import React from 'react';
/**
 * @function PageNotFound
 * @description - PageNotFound - building UI of PageNotFound Page .
 * showing the image with the text that the required page is not found
 * @returns {React.ReactElement} PageNotFound  UI
 * @example <PageNotFound/>
 */
export default function PageNotFound() {
  return <div style={{ margin: 100 }}>PageNotFound</div>;
}
