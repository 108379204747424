import React from 'react';
import { Box, Typography } from '@mui/material';
import LoaderImage from '../../assets/images/Loader.gif';

/**
 * @function Loader
 * @description Component to display a loading indicator.
 *
 * @returns {JSX.Element} JSX element representing the loader.
 */
const Loader = () => {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9999,
      }}
    >
      <img src={LoaderImage} alt="" width={300} height={300} />
      <Typography variant="body1" mt={2} style={{ color: '#fff' }}>
        Loading...
      </Typography>
    </Box>
  );
};

export default Loader;
