import axios from 'axios';
import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { API_ENDPOINTS } from '../../const/ApiEndpoints';
import { setDatasetList } from '../../state/slices/appSlice';
/**
 * @function useScenario
 * @description hook for Scenario Manager module
 * @returns {object} -returning multiple methods and states for Scenario Manager module
 * @example const {fetchScenarioList,fetchThresholdDataWithPagination,fetchDatasetColumnList}
 * =useScenario()
 */
const useScenario = () => {
  const dispatch = useDispatch();
  /**
   * @type {object}
   */
  const { app } = useSelector((state) => state);
  const [scenarioList, setScenarioList] = useState([]);
  const [datasetColumnList, setDatasetColumnList] = useState([]);
  /**
   * @function fetchScenarioList
   * @description API call for fetching and displaying list of scenaios
   * @returns {Promise} AxiosPromise
   * @example  fetchScenarioList()
   */
  const fetchScenarioList = () =>
    axios.get(API_ENDPOINTS.BASE_URL + API_ENDPOINTS.SCENARIO.LIST).then(
      (res) => {
        setScenarioList(res?.data);
        return res;
      },
      (err) => {
        throw err;
      }
    );
  /**
   * @type {function}
   * @function fetchDatasetList
   * @description API call for fetching list of datasets of a particular scenario
   * @param {object} datasetParams - scenario id
   * @returns {Promise} AxiosPromise
   * @example  fetchDatasetlist(datasetParams)
   */
  const fetchDatasetList = (datasetParams) => {
    const id = datasetParams?.UserDefScenario || datasetParams?.PreDefScenario;
    return axios
      .get(
        `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.SCENARIO_MANAGER.GET_DATSETS_OF_SCENARIO}${id}`
      )
      .then(
        (res) => {
          dispatch(setDatasetList(res?.data));
          return res;
        },
        (err) => {
          throw err;
        }
      );
  };
  /**
   * @function fetchDatasetRowsWithPagination
   * @description API call for fetching datasets rows with pagination
   * @param {number} datasetId - dataset Id
   * @param {number} pageIndex  - displaying page index of a table
   * @param {number} pageSize  - displaying page size of a table
   * @param {string} searchText - dataset to be searched
   * @returns {Promise} AxiosPromise
   * @example fetchDatasetRowsWithPagination(datasetId,pageIndex,pageSize,searchText)
   */
  const fetchDatasetRowsWithPagination = useCallback(
    (datasetId, pageIndex = 0, pageSize = 50, searchText = '') =>
      axios
        .get(
          `${API_ENDPOINTS.BASE_URL + API_ENDPOINTS.DATASET.DATA}${datasetId}`,
          {
            params: {
              page: pageIndex + 1,
              ...(pageSize ? { pagesize: pageSize } : {}),
              ...(searchText ? { search: searchText } : {}),
            },
          }
        )
        .then(
          (res) => ({
            datasetData: res.data.response,
            datasetRowsPageCount: Math.ceil(
              res.headers['x-total-count'] / pageSize
            ),
            datasetCount: res.headers['x-total-count'],
          }),
          () => ({
            datasetData: [],
            datasetRowsPageCount: 0,
            datasetCount: 0,
          })
        ),
    []
  );
  /**
   * @function fetchThresholdData
   * @description API call for fetching threshold data of a particular dataset
   * @param {number} data  - dataset Id
   * @returns {Promise} AxiosPromise
   * @example fetchThresholdData(data)
   */
  const fetchThresholdData = (data) =>
    axios
      .get(
        `${
          API_ENDPOINTS.BASE_URL + API_ENDPOINTS.DATASET.THRESHOLD_DATA
        }${data}`
      )
      .then(
        (res) => ({
          datasetData: res.data.response,
          datasetCount: res.headers['x-total-count'],
        }),
        (err) => {
          throw err;
        }
      );
  /**
   * @function fetchThresholdDataWithPagination
   * @description API call for fetching threshold data of a particular dataset with pagination
   * @param {number} datasetId - dataset Id
   * @param {number} pageIndex  - displaying page index of a table
   * @param {number} pageSize  - displaying page size of a table
   * @param {string} searchText - dataset to be searched
   * @returns {Promise} AxiosPromise
   * @example fetchThresholdDataWithPagination(datasetId,pageIndex,pageSize,searchText)
   */
  const fetchThresholdDataWithPagination = useCallback(
    (datasetId, pageIndex = 0, pageSize = 50, searchText = '') =>
      axios
        .get(
          `${
            API_ENDPOINTS.BASE_URL + API_ENDPOINTS.DATASET.THRESHOLD_DATA
          }${datasetId}`,
          {
            params: {
              page: pageIndex + 1,
              ...(pageSize ? { pagesize: pageSize } : {}),
              ...(searchText ? { search: searchText } : {}),
            },
          }
        )
        .then(
          (res) => ({
            datasetData: res.data.response,
            datasetRowsPageCount: Math.ceil(
              res.headers['x-total-count'] / pageSize
            ),
            datasetCount: res.headers['x-total-count'],
          }),
          (err) => {
            throw err;
          }
        ),
    []
  );
  /**
   * @function fetchDatasetColumnList
   * @description API call for fetching columns list of a particular dataset
   * @param {number} data  - dataset Id
   * @returns {Promise} AxiosPromise
   * @example fetchDatasetColumnList(data)
   */
  const fetchDatasetColumnList = (data) =>
    axios
      .get(API_ENDPOINTS.BASE_URL + API_ENDPOINTS.DATASET.COLUMN_DETAIL, {
        params: data,
      })
      .then(
        (res) => {
          setDatasetColumnList(res?.data?.columns);
          return res;
        },
        (err) => {
          throw err;
        }
      );

  useEffect(() => {
    if (app?.activeScenario?.scenario?.id) {
      if (app?.activeScenario?.scenario?.scenario_type === 'USER') {
        fetchDatasetList({
          UserDefScenario: app?.activeScenario?.scenario?.id,
        });
      } else {
        fetchDatasetList({
          PreDefScenario: app?.activeScenario?.scenario?.id,
        });
      }
    }
  }, [app?.activeScenario]);
  return {
    fetchScenarioList,
    scenarioList,
    setScenarioList,
    fetchDatasetList,
    fetchThresholdData,
    fetchDatasetColumnList,
    fetchDatasetRowsWithPagination,
    fetchThresholdDataWithPagination,
    datasetColumnList,
  };
};
export default useScenario;
