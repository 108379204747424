// @ts-nocheck
import React from 'react';
import { ReactComponent as DropDownIcon1 } from '../assets/images/ModuleDropDownLight.svg';

const theme = {
  spacing: 5,
  direction: 'ltr',
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    fontSize: 13.125,
    h1: {
      fontSize: '30px',
      fontWeight: 700,
    },
    h2: {
      fontSize: '24px',
      fontWeight: 600,
    },
    h3: {
      fontSize: '22px',
      fontWeight: 500,
    },
    h4: {
      fontSize: '18px',
      fontWeight: 500,
    },
    h5: {
      fontSize: '18px',
      fontWeight: 600,
    },
    h6: {
      fontSize: '16px',
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: '16px',
      fontWeight: 600,
    },
    subtitle2: {
      fontSize: '16px',
      fontWeight: 400,
    },
    subtitle3: {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '24px',
    },
    body1: {
      fontSize: '14px',
      fontWeight: 450,
    },
    body2: {
      fontSize: '14px',
      fontWeight: 600,
    },
    body3: {
      fontSize: '12px',
      fontWeight: 600,
    },
    body4: {
      fontSize: '12px',
      fontWeight: 400,
    },
    body5: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '22px',
    },
    body6: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '22px',
    },

    button: {
      fontSize: '16px',
      fontWeight: 500,
    },
    tmsButton: {
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '22px',
    },
  },
  styledRadio: {
    small: 13,
    standard: 15,
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '8px !important',
        },
      },
    },

    MuiChip: {
      styleOverrides: {
        root: {
          marginLeft: 20,
          '&:first-child': {
            marginLeft: 10,
          },
          paddingTop: 20,
          paddingBottom: 20,
          paddingLeft: 10,
          paddingRight: 10,
          borderTopLeftRadius: 26,
          borderTopRightRadius: 26,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
        label: {
          fontSize: 15,
          fontWeight: 500,
          lineHeight: 1.5,
          wordSpacing: 3,
          paddingTop: 5,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: '8px ',
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        maxSize: 'sm',
        fullWidth: true,
      },
      styleOverrides: {
        paper: {
          borderRadius: 1,
          padding: 16,
          paddingBottom: 60,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 18,
          paddingRight: 18,
          fontSize: '16px',
          fontWeight: '500',
          height: '50.5px',
          boxShadow: 'none !important',
          textTransform: 'none',
        },
        text: {
          textSize: 13.125,
        },
        contained: {
          border: 'none',
        },
        MuiButtonBase: {
          root: {
            MuiTab: {
              root: {
                height: 25,
                '&.Mui-selected': {
                  height: 25,
                },
              },
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          //   '& .MuiOutlinedInput-notchedOutline': {
          //     border: 'none',
          //   },
          //   '&:hover .MuiOutlinedInput-notchedOutline': {
          //     border: 'none',
          //   },
          //   '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          //     border: 'none',
          //   },

          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          '.MuiInputLabel-root': {
            padding: 5,
          },
          '& .MuiOutlinedInput-root': {
            height: 50.5,
          },

          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          zIndex: 1,
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          position: 'relative',
          zIndex: 0,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          width: '100%',
          display: 'block',
          borderBottom: 0,
        },
        selectLabel: {
          marginTop: 10,
        },
        displayedRows: {
          marginTop: 15,
        },
        caption: {
          userSelect: 'none',
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        // eslint-disable-next-line react/jsx-filename-extension
        popupIcon: <DropDownIcon1 width={18} height={18} />,
      },
      styleOverrides: {
        popupIndicator: {
          marginRight: 8,
        },
        paper: {
          marginTop: '5px',
          boxShadow: '0.1px 0.1px 0.1px 0.1px',
        },
        option: {
          marginLeft: '5px',
          marginRight: '5px',
          height: '40px',
          borderRadius: '4px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          margin: '10px 5px 10px',
          height: '40px',
          borderRadius: '6px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          display: 'flex',
          justifyContent: 'space-between',
          textTransform: 'uppercase',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontWeight: 600,
          textAlign: 'left',
          alignItems: 'center',
          paddingLeft: 35,
          paddingTop: 15,
          paddingBottom: 15,
          borderRight: 'none',
          '&:last-child': {
            borderRight: '1px solid',
          },
        },
        body: {
          wordWrap: 'break-word',
          textTransform: 'capitalize',
          textAlign: 'left',
          border: 'none',
          paddingTop: 20,
          paddingBottom: 10,
          paddingLeft: 35,
          fontWeight: 500,
          '&:last-child': {
            borderRight: '1px solid',
          },
          '&:first-of-type': {
            borderRight: 'none',
          },
        },
        sizeSmall: {
          '&:last-child': {
            paddingRight: 4,
            borderRight: 'none',
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          border: '1px solid',
          borderRadius: 5,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          display: 'flex',
          marginBottom: -15,
          padding: 0,
          textTransform: 'none',
          '&.Mui-selected': {
            padding: 0,
          },
        },
      },
    },

    MuiToolbar: {
      styleOverrides: {
        root: {
          MuiInputBase: {
            root: {
              border: 'none !important',
              width: '250px !important',
              height: '35px !important',
            },
            input: {
              border: 'none',
              width: '250px',
              height: '35px',
            },
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          paddingTop: 3,
          paddingBottom: 0,
          borderRadius: 0,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '& .MuiListItemButton-root': {
            '& .list-item-button-hover-actions-right': {
              display: 'none',
              position: 'absolute',
              right: '0',
            },
            '&:hover': {
              '& .list-item-button-hover-actions-right': {
                display: 'block',
              },
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        iconOutlined: {
          fontSize: 42,
        },
        select: {
          fontWeight: 500,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          height: '8px',
          '& .MuiSlider-track': {
            height: '10px',
          },
          '& .MuiSlider-rail': {
            height: '10px',
          },
          '& .MuiSlider-valueLabel': {
            top: '50px',
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          marginTop: '7px',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '.MuiOutlinedInput-root': {
            borderRadius: 4,
            height: 46.5,
          },
        },
      },
    },
  },
};

export default theme;
