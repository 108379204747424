import React from 'react';
import { Box, Backdrop } from '@mui/material';
import Loader from '../../assets/images/Loader.gif';
/**
 * @function PageLevelLoader
 * @description - PageLevelLoader - building UI of PageLevelLoader for showing images of loader
 * @returns {React.ReactElement} PageLevelLoader  UI
 * @example <PageLevelLoader/>
 */
export default function PageLevelLoader() {
  const [showLoader, setShowLoader] = React.useState(false);
  /**
   * @function showLoaderHandler
   * @description function for showing the loader image
   * @returns {void}
   * @example showLoaderHandler()
   */
  const showLoaderHandler = () => {
    setShowLoader(true);
  };
  /**
   * @function hideLoaderHandler
   * @description function for hiding the loader image
   * @returns {void}
   * @example hideLoaderHandler()
   */
  const hideLoaderHandler = () => {
    setShowLoader(false);
  };
  /* Adding the showLoader and hideLoader EventListeners for showing and hiding
  the loader image when the data is loaded */
  React.useEffect(() => {
    document.addEventListener('showLoader', showLoaderHandler, false);
    document.addEventListener('hideLoader', hideLoaderHandler, false);
  }, []);
  return (
    <Box>
      {showLoader ? (
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
          open={showLoader}
        >
          <Box>
            <img src={Loader} alt="" width={300} height={300} />
          </Box>
        </Backdrop>
      ) : null}
    </Box>
  );
}
