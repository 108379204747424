const REGEX = {
  FIRST_ALPHA_CAP: /^[A-Z]+[a-zA-Z]*$/, // only alphabets without space

  // a combination of alphabet, numbers special characters allowed
  // only the alphabet are allowed
  // only special character is not allowed
  LOGIN_USERNAME: /^[ A-Za-z0-9_@./#&+-]*$/,
  NOT_ONLY_NUMBER: '.*?[^0-9].*', // Not only numbers allowed
  ONLY_NUMBER: /^[0-9\b]+$/, // only number allowed
  MIN_SIX_CHAR: /^(?![\d!@#$%^&*()_+={}[\]:;<>,.?~\\/`'"|-]+$).{6,}$/, // Minimum 6 character required, all characters cannot be numbers and special characters
  POSITIVE_INT: /^[1-9][0-9]*$/, // only positive integer

  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
  USER_SCHEMA_NAME: /^(?=[a-zA-Z0-9])(?=.*[a-zA-Z])(?!.*\s).*$/,
  BLOCK_NAME: /^(?=[a-zA-Z0-9])(?=.*[a-zA-Z])(?!.*\s).*$/,
  ALPHA_NUMERIC_UNDERSCORE: /^[A-Za-z0-9_]+$/,
};
export default REGEX;
