// @ts-nocheck
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Autocomplete,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Toolbar,
  Tooltip,
  Avatar,
  Typography,
  Paper,
  IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

import Atoms from '../../assets/images/Atoms.png';
import Ologo from '../../assets/images/Ologo.svg';
import { ReactComponent as DropDownIcon } from '../../assets/images/ModuleDropDownDark.svg';
import { setActiveScenario, setDatasetList } from '../../state/slices/appSlice';
import Logoff from '../../assets/images/Logout.svg';

const menuProps = {
  '& .MuiPaper-root': {
    boxShadow: 1,
  },
};
// The below paper component is for Autocomplete
/**
 * @function PaperComponent
 * @description - PaperComponent - building UI of paper component for autocomplete
 * @param {object} children - data of a paper component
 * @returns {React.ReactElement} PaperComponent UI
 * @example <PaperComponent children={children}/>
 */
const PaperComponent = React.memo(({ children }) => (
  <Paper
    sx={{
      borderRadius: 2,
      mt: 1,
    }}
  >
    {children}
  </Paper>
));
PaperComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const AppHeader = ({ logout, scenarioList, open }) => {
  const { app } = useSelector((state) => state);
  const location = useLocation();
  const [dialogOpen, setDialogOpen] = useState(null);
  const [inputValueProject, setInputValueProject] = useState('');
  const sortedScenarioList = _.orderBy(scenarioList, 'scenario.id', 'desc');
  const dispatch = useDispatch();
  const scenariolist = app?.scenariolist;
  /**
   * @function handleCloseUser
   * @description function for handling logout of a user
   * @returns {undefined}
   * @example handleCloseUser()
   */
  const handleCloseUser = () => {
    localStorage.removeItem('navSelectedIndex');
    logout();
  };
  useEffect(() => {
    const isScenarioPresent = scenarioList?.some(
      (item) => item?.scenario?.id === app?.activeScenario?.scenario?.id
    );
    if (!isScenarioPresent && scenarioList?.length !== 0) {
      dispatch(setActiveScenario(null));
    }
  }, [app?.activeScenario?.scenario?.id, scenariolist]);
  return (
    <AppBar
      position="fixed"
      elevation={1}
      sx={{
        position: 'fixed',
        height: (theme) => theme.spacing(13),
        paddingRight: 0,
        // @ts-ignore
        backgroundColor: (theme) => theme.palette.common.sapphire,
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar>
        {open ? (
          <Box flexGrow="1" mt={2}>
            <img src={Atoms} alt="logo" height="35px" width="110px" />
          </Box>
        ) : (
          <Box flexGrow="1" mt={2}>
            <img src={Ologo} alt="logo" height="35px" />
          </Box>
        )}
        <Box
          sx={{
            width: {
              xs: '40vw',
              sm: '30vw',
              md: '25vw',
              lg: '20vw',
            },
            paddingRight: 2,
          }}
        >
          <Autocomplete
            autoHighlight
            popupIcon={<DropDownIcon width={15} height={12} />}
            value={app?.activeScenario}
            id="app_header_nav_bar_select_scenario"
            inputValue={inputValueProject}
            options={sortedScenarioList}
            disabled={location?.state}
            noOptionsText="No options"
            PaperComponent={PaperComponent}
            clearIcon={
              <IconButton
                onClick={() => {
                  dispatch(setActiveScenario(null));

                  dispatch(setDatasetList([]));
                }}
              >
                <Close />
              </IconButton>
            }
            sx={{
              // @ts-ignore
              backgroundColor: (theme) => theme.palette.common.lightBlue2,
              borderRadius: 10,
              '.MuiOutlinedInput-input': {
                backgroundColor: 'transparent !important',
                '&::placeholder': {
                  color: (theme) =>
                    `${theme.palette.common.sapphire} !important`,
                },
              },
              '.MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
                borderRadius: 10,
                fontWeight: 'medium',
                // @ts-ignore
                backgroundColor: (theme) =>
                  `${theme.palette.common.lightBlue2} !important`,
                color: (theme) =>
                  `${theme.palette.common.lightBlue1} !important`,
              },
            }}
            renderOption={(props, values) => (
              <Box component="li" {...props}>
                {values?.scenario?.id}-
                <Typography
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {values?.scenario?.name}
                </Typography>
              </Box>
            )}
            getOptionLabel={(values) =>
              values?.scenario?.name
                ? `${values?.scenario?.id}-${values?.scenario?.name}`
                : ''
            }
            onInputChange={(event, newInputValue) => {
              setInputValueProject(newInputValue);
            }}
            onChange={(event, newValue) => {
              if (newValue !== null) {
                dispatch(setActiveScenario(newValue));
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: <InputAdornment position="start" />,
                }}
                placeholder="Select Scenario"
                fullWidth
                variant="outlined"
                size="small"
              />
            )}
          />
        </Box>
        <Tooltip title={app?.auth?.user?.username} placement="bottom">
          <Avatar
            src={Logoff}
            sx={{ width: 60, height: 60, cursor: 'pointer' }}
            onClick={(event) => {
              setDialogOpen(event?.currentTarget);
            }}
          />
        </Tooltip>
        <Menu
          anchorEl={dialogOpen}
          id="account-menu"
          open={Boolean(dialogOpen)}
          onClick={() => {
            setDialogOpen(null);
          }}
          PaperProps={{
            elevation: 1,
            sx: menuProps,
          }}
          transformOrigin={{
            horizontal: 'left',
            vertical: 'top',
          }}
          anchorOrigin={{
            horizontal: 'left',
            vertical: 'bottom',
          }}
        >
          <MenuItem disabled>{`Hello ${app?.auth?.user?.username} !`}</MenuItem>
          <MenuItem
            onClickCapture={() => {
              handleCloseUser();
            }}
          >
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};
AppHeader.propTypes = {
  scenarioList: PropTypes.oneOfType([PropTypes.object]).isRequired,
  logout: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
};
export default AppHeader;
