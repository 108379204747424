import { createTheme, alpha } from '@mui/material/styles';
import _ from 'lodash';
import baseTheme from './Base';

export default createTheme(
  // @ts-ignore
  _.merge(baseTheme, {
    themeName: 'Light',
    palette: {
      type: 'light',
      common: {
        black: '#000000',
        black1: '#0C0D0D',
        white: '#ffffff',
        blue: '#0072B1',
        green: '#8EE58B',
        yellow: '#E8F258',
        lightcyan: '#cff7f2',
        lightgreen: '#cdffcd',
        lightergreen: '#CFF7F2',
        lightBlue: '#f2f8fc',
        lightBlue1: '#C2D2EF',
        lightBlue2: '#DCE1F5',
        lightBlue3: '#DDE8FC',
        lightBlue4: '#F5F8FF',
        lightBlue5: '#C7D7F2',
        lightBlue6: '#CCDBF6',
        lightBlue7: '#B8C3D6',
        tundora: '#4E4949',
        beauBlue: '#D1E5F1',
        grey: '#EFF0F9',
        grey1: '#EFF5FF',
        grey3: '#C5C5C5',
        lightYellow: '#F5F5F5',
        aliceBlue: '#EDF5FA',
        echoBlue: '#99ADD1',
        echoBlue1: '#99ADD124',
        echoBlue2: '#A0AEC7',
        hawkesBlue: '#CDDAEF',
        sapphire: '#05204A',
        zircon: '#85949466',
        red: '#F13012',
        tomatoRed: '#F14F4F',
        orange: '#FFD1D1',
        sunset: '#FFBD9B',
        shipCove: '#7388A8',
        tint: '#01A601',
        cornflowerBlue: '#6495ed',
        lightGrey: '#A9A9A9',
        lightGrayBorder: '#959595',
        shadowColor: '#00000033',
        TMSgreen: '#0bb7a7',
        TMSred: '#FF0000',
        TMSyellow: '#F5DD00',
        infoGray: '#6f6f6f',
        tabHeaderBackgroundColor: '#e7eeff',
        spanish_gray: '#919191',
        lightText: '#303030B2',
        offWhite: '#FFFFFF80',
        lightBorder: '#E9E9E9',
        thresholdBorder: '#D9D9D9',
        lightestBorder: '#D9D9D9',
        lightestLabel: '#565656',
        lightDescription: '#525761',
        materialBlue: '#407BFF',
        mineShaft: '#333333',
        scenarioBorder: '#0000000F',
        gallery: '#eaeaea',
      },
      primary: {
        light: '#7986cb',
        main: '#05204A',
        dark: '#05204A',
        contrastText: '#fff',
      },
      secondary: {
        light: '#CDDAEF',
        main: '#99ADD1',
        lightMain: '#F2F6FF',
      },

      other: {
        stepperColor: {
          solidLine: '#55555560',
          dashedLine: '#55555530',
        },

        fileUpload: {
          borderColor: '#AFAFAF',
        },

        mappingStatus: {
          draft: '#B0B0B0',
          mapped: '#87CEEB',
          ready: '#32CD32',
          notReady: '#ff754a',
          success: '#018749',
          failed: '#FF0000',
          upstreamFailed: '#FFA500',
          inQueue: '#FFD700',
          running: '#0CAFFF',
          aborted: '#BD3039',
        },
        reportBackground: '#407BFF1A',
      },
    },
    components: {
      MuiDialog: {
        styleOverrides: {
          paper: {
            backgroundColor: '#EFF1FF',
            borderRadius: '6px',
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: '#05204A',
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          input: {
            '&::placeholder': {
              color: '#99ADD1',
            },
            color: '#05204A',
          },
          option: {
            '&:hover': {
              backgroundColor: '#F2F6FE !important',
            },
            '&[aria-selected="true"]': {
              backgroundColor: '#E4EDFF !important',
            },
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: '#CDDAEF',
              color: '#05204A',
            },
            '&.Mui-selected:hover': {
              backgroundColor: '#CDDAEF',
              color: '#05204A',
            },
            '&:hover': {
              backgroundColor: '#CDDAEF',
              color: '#05204A',
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {},
          contained: {
            backgroundColor: '#05204A !important',
          },
          outlined: {
            color: '#05204A !important',
            borderColor: '#05204A !important',
            '&:hover': {
              color: '#ffffff !important',
              backgroundColor: '#05204A !important',
              borderColor: '#05204A !important',
            },
          },
          secondaryOutlined: {
            '&': {
              backgroundColor: '#FFFFFF',
              border: '1px solid #D9D9D9',
              color: '#000000D9',
            },
            '&:hover': {
              backgroundColor: '#F2F2F2',
              borderColor: '#d6d4d4',
            },
          },

          containedDanger: {
            '&': { background: '#ff4842', color: '#ffffff' },
            '&:hover': {
              background: '#b72136',
            },
          },

          secondaryContained: {
            '&': {
              color: '#00000080',
              background: '#F5F5F5',
              border: '1px solid #C2C2C2',
            },

            '&:hover': {
              background: '#F5F5F595',
            },
          },

          MuiButtonBase: {
            root: {
              MuiTab: {
                root: {
                  backgroundColor: 'ffffff',
                  '&.Mui-selected': {
                    backgroundColor: 'ffffff',
                  },
                },
              },
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '.MuiInputBase-input': {
              color: '#05204A',
              '&::placeholder': {
                color: '#99ADD1',
              },
            },
            '.MuiInputLabel-root': {
              color: '#99ADD1',
            },
            '.MuiInputLabel-root.Mui-disabled': {
              color: alpha('#000000', 0),
            },
            '.MuiInputLabel-root.Mui-focused': {
              color: alpha('#000000', 0),
            },
            '.MuiInputLabel-shrink': {
              color: alpha('#000000', 0),
            },
            '&.MuiPopover-paper': {
              color: '#ffffff',
              backgroundColor: '#ffffff',
            },
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            background: '#FFFFFF',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            color: '#05204A',
            border: `1px solid ${alpha('#99ADD1', 0.4)}`,
            '&:last-child': {
              borderColor: 'none',
            },
            '&:first-of-type': {
              borderColor: 'none',
            },
          },
          stickyHeader: {
            backgroundColor: '#DDE8FC',
          },
          body: {
            borderColor: '#99ADD1',
            '&:last-child': {
              borderColor: '#99ADD1',
            },
            '&:first-of-type': {
              borderColor: '#99ADD1',
            },
          },
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          icon: {
            color: '#05204A !important',
          },
        },
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            borderColor: '#99ADD1',
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          select: {
            background: '#FFFFFF',
          },
          toolbar: {
            background: '#F5F8FF',
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {},
          indicator: {
            backgroundColor: '#05204A',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              color: '#05204A',
            },
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            MuiInputBase: {
              root: {
                backgrounColor: '#ffffff !important',
              },
              input: {
                backgrounColor: '#ffffff',
              },
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          iconOutlined: {
            color: '#99ADD1',
          },
          select: {
            '&::placeholder': {
              color: '#99ADD1',
            },
            color: '#05204A',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: '#F2F6FE !important',
            },
            '&[aria-selected="true"]': {
              backgroundColor: '#E4EDFF !important',
            },
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          root: {
            color: '#ffffff',
            '& .MuiSlider-track': {
              color: '#CDDDF9',
            },
            '& .MuiSlider-thumbColorPrimary': {
              color: '#05204A',
            },
            '& .MuiSlider-valueLabel': {
              background: 'none',
              color: '#000000',
            },
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            '.MuiOutlinedInput-root': {
              backgroundColor: '#ffffff',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            '&:disabled': {
              '-webkit-text-fill-color': '#99ADD1',
            },
          },
        },
      },
    },
  })
);
