import EdaIcon from '../assets/images/sidebar/Eda.svg';
import EdaIconDark from '../assets/images/sidebar/EdaDark.svg';
import Line from '../assets/images/sidebar/Line.svg';
import LineDark from '../assets/images/sidebar/LineDark.svg';
import SamplingIcon from '../assets/images/sidebar/Sampling.svg';
import SamplingIconDark from '../assets/images/sidebar/SamplingDark.svg';
// import ETAIcon from '../assets/images/sidebar/Eta.svg';
// import ETAIconDark from '../assets/images/sidebar/EtaDark.svg';
import OutlierIcon from '../assets/images/sidebar/Outlier.svg';
import OutlierIconDark from '../assets/images/sidebar/OutlierDark.svg';
import TDAIcon from '../assets/images/sidebar/TuningData.svg';
import TDAIconDark from '../assets/images/sidebar/TuningDataDark.svg';
import ImpactAnalysisIcon from '../assets/images/sidebar/Impact.svg';
import ImpactAnalysisIconDark from '../assets/images/sidebar/ImpactDark.svg';
import ScenarioManager from '../assets/images/sidebar/ScenarioManager.svg';
import ScenarioManagerDark from '../assets/images/sidebar/ScenarioManagerDark.svg';
import DataManager from '../assets/images/sidebar/DataManager.svg';
import AutomatedDocumentation from '../assets/images/sidebar/AutomatedDocumentation.svg';
// import Reconciliation from '../assets/images/sidebar/Reconciliation.svg';
// import ReconciliationDark from '../assets/images/sidebar/ReconciliationDark.svg';
import DataOverviewIcon from '../assets/images/sidebar/DataOverview.svg';
import AuditLogs from '../assets/images/sidebar/Audit_log.svg';

const SIDEBAR_MENUS = [
  // {
  //   name: 'ETL Manager',
  //   icon: ETAIcon,
  //   iconActive: ETAIconDark,
  //   route: '/etl',
  // },

  {
    name: 'Data Manager',
    icon: DataManager,
    iconActive: DataManager,
    route: '/datamanager/dataingestion',
  },
  {
    name: 'Scenario Manager',
    icon: ScenarioManager,
    iconActive: ScenarioManagerDark,
    route: '/scenariomanager',
  },
  {
    name: 'Tuning Manager',
    icon: DataOverviewIcon,
    iconActive: DataOverviewIcon,
    items: [
      {
        name: 'Data Overview',
        icon: Line,
        iconActive: LineDark,
        route: '/tuningManager/dataOverview',
      },
      {
        name: 'Tuning Overview',
        icon: Line,
        iconActive: LineDark,
        route: '/tuningManager/tuningOverview',
      },
    ],
  },
  {
    name: 'Exploratory Analysis',
    icon: EdaIcon,
    iconActive: EdaIconDark,
    route: '/exploratoryAnalysis',
  },
  {
    name: 'Outlier Analysis',
    icon: OutlierIcon,
    iconActive: OutlierIconDark,
    route: '/outlierAnalysis',
  },
  {
    name: 'Tuning Analysis',
    icon: TDAIcon,
    iconActive: TDAIconDark,
    items: [
      {
        name: 'Tuning Data',
        icon: Line,
        iconActive: LineDark,
        route: '/tuningAnalysis/tuningData',
      },
      {
        name: 'Consolidated View',
        icon: Line,
        iconActive: LineDark,
        route: '/tuningAnalysis/consolidatedView',
      },
    ],
  },
  {
    name: 'Sampling',
    icon: SamplingIcon,
    iconActive: SamplingIconDark,
    items: [
      {
        name: 'ATL/BTL Analysis',
        icon: Line,
        iconActive: LineDark,
        route: '/sampling/atlbtlAnalysis',
      },
      {
        name: 'Sample Generation',
        icon: Line,
        iconActive: LineDark,
        route: '/sampling/sampleGeneration',
      },
    ],
  },
  {
    name: 'Impact Analysis',
    icon: ImpactAnalysisIcon,
    iconActive: ImpactAnalysisIconDark,
    items: [
      {
        name: 'Event Generation',
        icon: Line,
        iconActive: LineDark,
        route: '/impactAnalysis',
      },
      {
        name: 'What-If Analysis',
        icon: Line,
        iconActive: LineDark,
        route: '/whatIf',
      },
    ],
  },
  // {
  //   name: 'Impact Analysis',
  //   icon: ImpactAnalysisIcon,
  //   iconActive: ImpactAnalysisIconDark,
  //   route: '/impactAnalysis',
  // },
  {
    name: 'Automated Documentation',
    icon: AutomatedDocumentation,
    iconActive: AutomatedDocumentation,
    route: '/documentation',
  },
  // {
  //   name: 'Reconciliation',
  //   icon: Reconciliation,
  //   iconActive: ReconciliationDark,
  //   route: '/reconciliation',
  // },

  {
    name: 'History',
    icon: AuditLogs,
    iconActive: AuditLogs,
    items: [
      {
        name: 'Auditability',
        icon: Line,
        iconActive: LineDark,
        route: '/history/auditability',
      },
      {
        name: 'Scenario History',
        icon: Line,
        iconActive: LineDark,
        route: '/history/scenarioHistory',
      },
      {
        name: 'Threshold History',
        icon: Line,
        iconActive: LineDark,
        route: '/history/thresholdHistory',
      },
    ],
  },
];

export { SIDEBAR_MENUS };
