import axios from 'axios';
import keycloakConfig from '../keyclock-config';

const IntializeAxios = ({ enqueueSnackbar }) => {
  const headersCommonOptions = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  const showLoaderEvent = new Event('showLoader', { bubbles: true });
  const hideLoaderEvent = new Event('hideLoader', { bubbles: true });
  let numberOfApiCallsPending = 0;
  axios.defaults.headers.common = headersCommonOptions;
  axios.interceptors.request.use((req) => {
    // `req` is the Axios request config, so you can modify
    // the `headers`.
    const requiresToken = req.headers['Token-Requirement'] !== 'not-required';

    if (keycloakConfig && keycloakConfig.token && requiresToken) {
      req.headers.Authorization = `Bearer ${keycloakConfig.token}`;
    }
    numberOfApiCallsPending += 1;
    if (req?.loader === false) {
      numberOfApiCallsPending = 0;
      document.dispatchEvent(hideLoaderEvent);
    } else {
      document.dispatchEvent(showLoaderEvent);
    }
    return req;
  });

  axios.interceptors.response.use(
    (response) => {
      numberOfApiCallsPending -= 1;
      if (numberOfApiCallsPending <= 0) {
        numberOfApiCallsPending = 0;
        setTimeout(() => {
          document.dispatchEvent(hideLoaderEvent);
        }, 800);
      }
      return response;
    },
    (error) => {
      numberOfApiCallsPending -= 1;
      if (numberOfApiCallsPending === 0) {
        setTimeout(() => {
          document.dispatchEvent(hideLoaderEvent);
        }, 800);
      }
      if (error.response?.status === 401) {
        localStorage.clear();
        enqueueSnackbar('Session timeout, Login again!', {
          variant: 'error',
        });
        setTimeout(() => {
          keycloakConfig.logout();
        }, 800);
      }
      if (error.response?.status === 403) {
        localStorage.clear();
        enqueueSnackbar(
          'User Not Authorized to Access this Application, Contact Administrator for Permissions',
          {
            variant: 'error',
          }
        );
        setTimeout(() => {
          keycloakConfig.logout();
          localStorage.clear();
        }, 900);
      }
      const toast = error?.config?.headers?.toast;

      if (error.response?.data?.msg) {
        if (toast !== 'hide')
          enqueueSnackbar(error.response?.data?.msg, {
            variant: 'error',
          });
      }
      return Promise.reject(error);
    }
  );
};

export default IntializeAxios;
