import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { useDispatch, useSelector } from 'react-redux';

import { MAX_PAGINATION_PAGE_SIZE } from '../../const/CommonConst';
import { setScenarioList } from '../../state/slices/appSlice';
import useCreateScenario from '../../hooks/ScenarioManager/CreateScenario/useCreateScenario';
import Sidebar from '../../components/Sidebar/Sidebar';
import AppHeader from '../../components/AppHeader/AppHeader';
import useAuth from '../../hooks/Auth/useAuth';
import usePushNotifications from '../../hooks/usePushNotifications';
/**
 * @function Layout
 * @description - Layout - building UI of Layout module .
 * @returns {React.ReactElement} Layout UI
 * @example <Layout/>
 */
export default function Layout() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const { app } = useSelector((state) => state);
  const [list, setList] = useState(false);

  const { getAllScenarios } = useCreateScenario();

  const { logout } = useAuth();
  const [open, setOpen] = React.useState(false);
  const drawerWidth = !open ? 58 : 240;
  const { subscribe, handleNotificationClick } = usePushNotifications();
  const dispatch = useDispatch();
  const scenarioList = app?.scenariolist;
  const fetchScenarioList = () => {
    getAllScenarios({
      pageNumber: 1,
      pageSize: MAX_PAGINATION_PAGE_SIZE,
      searchQuery: '',
    }).then((res) => {
      dispatch(setScenarioList(res?.data));
    });
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  useEffect(() => {
    subscribe();
  }, []);
  useEffect(() => {
    if (!localStorage.getItem('navSelectedIndex')) {
      localStorage.setItem('navSelectedIndex', '/datamanager/dataingestion');
    }
    fetchScenarioList();
    setList(false);
  }, [app?.activeScenario?.scenario?.id, list]);
  useEffect(() => {
    localStorage.setItem('navSelectedIndex', window?.location?.pathname);
    fetchScenarioList();
    setList(false);
  }, [window?.location?.pathname]);
  useEffect(() => {
    handleNotificationClick();
  }, [handleNotificationClick]);
  return (
    <Box
      display="flex"
      sx={{
        // @ts-ignore
        backgroundColor: (theme) => theme.palette.common.lightBlue4,
      }}
    >
      <CssBaseline />
      <AppHeader
        scenarioList={scenarioList}
        logout={logout}
        open={open}
        handleDrawerToggle={handleDrawerToggle}
      />
      <Sidebar
        open={open}
        setOpen={setOpen}
        handleDrawerToggle={handleDrawerToggle}
        mobileOpen={mobileOpen}
        drawerWidth={drawerWidth}
      />
      <Box
        sx={{
          paddingTop: '100px',
          overflowY: 'auto',
          overflowX: 'hidden',
          width: {
            lg: `calc(100% - ${drawerWidth}px)`,
            sm: 'calc(100% - 50px)',
          },
        }}
        onClick={() => {
          setOpen(false);
        }}
      >
        <Outlet context={{ setList }} />
      </Box>
    </Box>
  );
}
