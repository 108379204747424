// @ts-nocheck
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeScenario: JSON.parse(localStorage.getItem('ACTIVE_SCENARIO')) ?? null,
  datasetList: [],
  preferences: {
    theme: 'Light',
  },
  partitionName: '',
  scenariolist: [],
  auth: {
    // eslint-disable-next-line no-unneeded-ternary
    isLoggedIn: localStorage.getItem('isLoggedIn') === 'true' ? true : false,
    token: localStorage.getItem('token'),
    refresh: localStorage.getItem('refreshToken'),
    user: JSON.parse(localStorage.getItem('user')),
  },
  status: 'idle',
  scenarioHistorySearch: '',
  auditabilitySearch: '',
  thresoldHistorySearch: '',
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const incrementAsync = createAsyncThunk(
  'counter/changeTheme',
  async (amount) => {
    const response = { data: amount };
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const appSlice = createSlice({
  name: 'app',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setTheme: (state) => {
      const states = state;
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      states.value += 1;
    },
    setAuth: (state, action) => {
      const states = state;
      states.auth.isLoggedIn = action.payload.isLoggedIn;
      states.auth.token = action.payload.token;
      states.auth.refreshToken = action.payload.refresh;
      states.auth.user = action.payload.user;
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('isLoggedIn', action.payload.isLoggedIn);
      localStorage.setItem('refreshToken', action.payload.refresh);
      localStorage.setItem('user', JSON.stringify(action.payload.user));
      // window?.cookieStore?.set('user', JSON.stringify(action.payload.user)); // removing it to fix VAPT Issue
    },
    setActiveScenario: (state, action) => {
      const states = state;
      localStorage.setItem('ACTIVE_SCENARIO', JSON.stringify(action.payload));
      states.activeScenario = action.payload;
    },
    setDatasetList: (state, action) => {
      const states = state;
      states.datasetList = action.payload;
    },
    setColoumnPartitionName: (state, action) => {
      const states = state;
      states.partitionName = action.payload;
    },

    setScenarioList: (state, action) => {
      const states = state;
      states.scenariolist = action.payload;
    },

    setScenarioHistorySearch: (state, action) => {
      return { ...state, scenarioHistorySearch: action.payload };
    },

    setAuditabilitySearch: (state, action) => {
      return { ...state, auditabilitySearch: action.payload };
    },

    setThresoldHistorySearch: (state, action) => {
      return { ...state, thresoldHistorySearch: action.payload };
    },

    // Use the PayloadAction type to declare the contents of `action.payload`
    // incrementByAmount: (state, action) => {
    //   state.value += action.payload;
    // },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(incrementAsync.pending, (state) => {
        const states = state;
        states.status = 'loading';
      })
      .addCase(incrementAsync.fulfilled, (state) => {
        const states = state;
        states.status = 'idle';
        // state.value += action.payload;
      });
  },
});

export const {
  setTheme,
  setAuth,
  setActiveScenario,
  setDatasetList,
  setColoumnPartitionName,
  setScenarioList,
  setScenarioHistorySearch,
  setAuditabilitySearch,
  setThresoldHistorySearch,
} = appSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectCount = (state) => state.counter.value;
export const selectActiveTheme = (state) => state.app.preferences.theme;
export const selectAuth = (state) => state.app.auth;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount) => (dispatch, getState) => {
//   const currentValue = selectCount(getState());
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount));
//   }
// };

export default appSlice.reducer;
