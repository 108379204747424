import React, { useEffect, useState } from 'react';
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom';
import Login from '../pages/Login/Login';
import ProtectedRoutes from './ProtectedRoutes';
import keycloakConfig from '../keyclock-config';
import useAuth from '../hooks/Auth/useAuth';
import Loader from '../components/Common/Loader';

const AppRoutes = () => {
  const { loginDetails } = useAuth();
  const [authKeyClock, setAuthKeyClock] = useState(false);
  const [initializing, setInitializing] = useState(true);

  useEffect(() => {
    const initializeKeycloak = async () => {
      if (!authKeyClock) {
        try {
          const auth = await keycloakConfig.init({
            onLoad: 'login-required',
            KeycloakResponseType: 'code',
            silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
            checkLoginIframe: false,
            pkceMethod: 'S256',
          });

          if (!auth) {
            window.location.reload();
          } else {
            keycloakConfig.onTokenExpired = () => {
              console.log('token expired');
            };
            loginDetails();
            setAuthKeyClock(true);
          }
        } catch (error) {
          console.error('Authentication Failed', error);
        } finally {
          setInitializing(false);
        }
      }
    };

    initializeKeycloak();
  }, [authKeyClock]);

  if (initializing) {
    // Show loading or some kind of spinner while Keycloak is initializing
    return <Loader />;
  }

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route
          path="/*"
          element={<ProtectedRoutes isLoggedIn={authKeyClock} />}
        />
        <Route path="/reset" element={<Login />} />
      </Route>
    )
  );

  return (
    <React.Suspense fallback="">
      <RouterProvider router={router} />
    </React.Suspense>
  );
};
export default AppRoutes;
