/* eslint-disable no-useless-escape */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box, Card, CardHeader } from '@mui/material';
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import PersonIcon from '@mui/icons-material/Person';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LockIcon from '@mui/icons-material/Lock';
import * as Yup from 'yup';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useSearchParams, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/Auth/useAuth';
import Atoms from '../../assets/images/LoginAtomsLogo.png';
import PoweredBy from '../../assets/images/NewPoweredBy.svg';
import AtomsView from '../../assets/images/AtomsLogin.gif';
import REGEX from '../../const/Regex';

const intputStyle = {
  '.MuiInputBase-root': {
    backgroundColor: (theme) => theme.palette.common.white,
  },
};

const formValidationSchema = Yup.object({
  username: Yup.string()
    .matches(REGEX.LOGIN_USERNAME, 'Incorrect input')
    .required('Required!'),
  password: Yup.string()
    .matches(REGEX.LOGIN_USERNAME, 'Incorrect input')
    .required('Password Required!'),
});

const validationSchemas = Yup.object({
  email: Yup.string().email('Invalid email').required('Email Required!'),
});

// eslint-disable-next-line no-useless-escape
const strongPassword =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/;
const resetSchema = Yup.object().shape({
  password: Yup.string()
    .required('This field is required')
    .matches(
      strongPassword,
      'Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
    ),
  confirmPassword: Yup.string().when('password', {
    is: (val) => !!(val && val.length > 0),
    then: Yup.string().oneOf(
      [Yup.ref('password')],
      'Both password need to be the same'
    ),
  }),
});
/**
 * @function Login
 * @description - Login - building UI of Login module .
 * @returns {React.ReactElement} Login UI
 * @example <Login/>
 */
function Login() {
  const { login, isLoggedIn, resetPassword, changeNewPassword } = useAuth();
  const [forgetPass, setForgetpass] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const uidb = searchParams?.get('uidb64');
  const tokens = searchParams?.get('token');
  const loginForm = useFormik({
    initialValues: {
      username: 'atoms',
      password: 'atoms',
      hidden: true,
    },
    onSubmit: (values) => {
      login(values);
    },
    validationSchema: formValidationSchema,
  });
  const forgetPassword = useFormik({
    initialValues: {
      email: '',
      domain: window.location.href.split('/')[2],
    },
    validationSchema: validationSchemas,
    onSubmit: (values) => {
      resetPassword(values).then((res) => {
        setForgetpass(false);
        enqueueSnackbar(
          res?.status === 200 ? res?.data?.success : 'No Account found',
          {
            variant: res?.status === 200 ? 'success' : 'error',
          }
        );
      });
    },
  });
  const navigate = useNavigate();
  const changePassword = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
      hidden: true,
    },
    validationSchema: resetSchema,
    onSubmit: (values) => {
      const data = {
        password: values.password,
        uidb64: uidb,
        token: tokens,
      };
      changeNewPassword(data).then((res) => {
        if (res?.status === 200) {
          navigate('/login');
        }
        enqueueSnackbar(
          res?.status === 200
            ? res?.data?.message
            : 'Ensure this field has at least 6 characters.',
          {
            variant: res?.status === 200 ? 'success' : 'error',
          }
        );
      });
    },
  });
  useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
    }
  }, [isLoggedIn]);
  const [values, setValues] = useState(loginForm.initialValues);
  /**
   * @function handleClickShowPassword
   * @description - handleClickShowPassword - function for showing the password on click of a button
   * @returns {void}
   * @example handleClickShowPassword()
   */
  const handleClickShowPassword = () => {
    setValues({ ...values, hidden: !values.hidden });
  };
  const [keys, setKeys] = useState(changePassword.initialValues);
  const [checked, setchecked] = useState(false);
  /**
   * @function handleClickShowResetPassword
   * @description - handleClickShowResetPassword -function for reset
   * the password on click of a button
   * @returns {void}
   * @example handleClickShowResetPassword()
   */
  const handleClickShowResetPassword = () => {
    setKeys({ ...keys, hidden: !keys.hidden });
  };
  /**
   * @function handleMouseDownPassword
   * @description - handleMouseDownPassword -function for handling the password field
   *  on mousedown event
   * @param {object} event - event object
   * @returns {void}
   * @example handleMouseDownPassword(event)
   */
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  /**
   * @function handleOpenForm
   * @description - handleOpenForm -function to open the form on click of a forget password button
   * @returns {void}
   * @example handleOpenForm()
   */
  const handleOpenForm = () => {
    setForgetpass(true);
    forgetPassword.resetForm();
  };
  const handlechecked = () => {
    setchecked(!checked);
  };

  const theme = useTheme();
  return (
    <Box
      style={{
        background: `linear-gradient(180deg, ${theme.palette.common.shipCove} -10.59%, ${theme.palette.common.sapphire}  78.88%)`,
      }}
      display="grid"
      gridTemplateColumns="2.1fr 1fr"
    >
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        flexGrow={0.6}
        height="100vh"
      >
        <Box
          display="flex"
          alignItems="center"
          flexDirection="row"
          flexGrow={1}
        >
          <img
            src={AtomsView}
            style={{
              height: '99vh',
              maxWidth: '100%',
            }}
            alt="gears"
          />
        </Box>
      </Box>
      {window.location.href.split('/')[3].split('?')[0] === 'reset' ? (
        <Box
          display="flex"
          alignItems="center"
          flexDirection="row"
          height="100vh"
          flexGrow={0.4}
        >
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            width="100%"
            pr={10}
          >
            <Grid item xs={12}>
              <Card
                elevation={0}
                sx={{
                  // @ts-ignore
                  backgroundColor: (theme) => theme.palette.common.shipCove,
                  borderRadius: '25px',
                }}
              >
                <CardHeader
                  title="Reset Password"
                  titleTypographyProps={{
                    align: 'center',
                    style: { paddingTop: '10px', paddingBottom: '10px' },
                    color: 'white',
                  }}
                >
                  Reset Password
                </CardHeader>
                <Box pl={4} pr={4} pb={4} pt={1} mb={4}>
                  <Box mb={3} mt={2}>
                    <TextField
                      id="password"
                      type="text"
                      size="small"
                      name="password"
                      variant="outlined"
                      placeholder="Enter new password"
                      fullWidth
                      error={
                        changePassword?.errors?.password &&
                        changePassword?.touched?.password
                      }
                      value={changePassword?.values?.password}
                      helperText={changePassword?.errors?.password}
                      onChange={changePassword?.handleChange}
                    />
                  </Box>

                  <Box mb={2}>
                    <TextField
                      sx={intputStyle}
                      id="confirmPassword"
                      size="small"
                      name="confirmPassword"
                      type={keys?.hidden ? 'password' : 'text'}
                      variant="outlined"
                      placeholder="Confirm password"
                      fullWidth
                      error={
                        changePassword?.errors?.confirmPassword &&
                        changePassword?.touched?.confirmPassword
                      }
                      value={changePassword?.values?.confirmPassword}
                      helperText={changePassword?.errors?.confirmPassword}
                      onChange={changePassword?.handleChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              className="icon"
                              aria-label="toggle password visibility"
                              onClick={handleClickShowResetPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {values.hidden ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>

                  <Box
                    display="flex"
                    alignItems="center"
                    flexGrow={1}
                    flexDirection="column"
                    mt={4}
                  >
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      style={{ width: '318px' }}
                      onClick={() => changePassword.handleSubmit()}
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
                <Box display="flex" justifyContent="flex-end" pr={4} pb={4}>
                  <img
                    height="32px"
                    width="80px"
                    src={PoweredBy}
                    alt="PoweredBy Solytics Partners"
                  />
                </Box>
              </Card>
            </Grid>
          </Box>
        </Box>
      ) : forgetPass ? (
        <Box
          display="flex"
          alignItems="center"
          flexDirection="row"
          height="100vh"
          flexGrow={0.4}
        >
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            width="100%"
            pr={10}
          >
            <Grid item xs={12}>
              <Card
                elevation={0}
                sx={{
                  // @ts-ignore
                  backgroundColor: (theme) => theme.palette.common.shipCove,
                  borderRadius: '25px',
                }}
              >
                <CardHeader
                  title="Forgot Password"
                  titleTypographyProps={{
                    align: 'center',
                    style: { paddingTop: '10px', paddingBottom: '10px' },
                    color: 'white',
                  }}
                >
                  Forgot Password
                </CardHeader>
                <Box pl={4} pr={4} pb={4} pt={1} mb={4}>
                  <Box mb={3} mt={2}>
                    <TextField
                      sx={intputStyle}
                      id="email"
                      type="text"
                      size="small"
                      name="email"
                      variant="outlined"
                      placeholder="Enter your registered email id!"
                      fullWidth
                      error={
                        forgetPassword?.errors?.email &&
                        forgetPassword?.touched?.email
                      }
                      value={forgetPassword?.values?.email}
                      helperText={forgetPassword?.errors?.email}
                      onChange={forgetPassword?.handleChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AlternateEmailIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box display="flex" justifyContent="flex-end" mb={4}>
                    <Typography
                      sx={{
                        color: (theme) => theme.palette.common.white,
                        '&:hover': {
                          // @ts-ignore
                          color: (theme) => theme.palette.common.sapphire,
                        },
                        cursor: 'pointer',
                      }}
                      type="submit"
                      variant="text"
                      fontSize={12}
                      onClick={() => setForgetpass(false)}
                    >
                      login?
                    </Typography>
                  </Box>

                  <Box
                    display="flex"
                    alignItems="center"
                    flexGrow={1}
                    flexDirection="column"
                    mt={4}
                  >
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      style={{ width: '318px' }}
                      onClick={() => forgetPassword.handleSubmit()}
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
                <Box display="flex" justifyContent="flex-end" pr={4} pb={4}>
                  <img
                    height="32px"
                    width="80px"
                    src={PoweredBy}
                    alt="PoweredBy Solytics Partners"
                  />
                </Box>
              </Card>
            </Grid>
          </Box>
        </Box>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          flexDirection="row"
          height="100vh"
          flexGrow={0.4}
        >
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            width="100%"
            pr={10}
          >
            <Grid item xs={12}>
              <Card
                elevation={0}
                sx={{
                  // @ts-ignore
                  backgroundColor: (theme) => theme.palette.common.grey,
                  borderRadius: '15px',
                }}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  sx={{ paddingTop: '40px' }}
                >
                  <img
                    height="40px"
                    width="122px"
                    src={Atoms}
                    alt="Atoms logo"
                  />
                </Box>
                <CardHeader
                  title="Log In"
                  titleTypographyProps={{
                    align: 'center',
                    style: { paddingTop: '10px', paddingBottom: '1px' },
                  }}
                >
                  Log In
                </CardHeader>
                <Box pl={4} pr={4} pb={4} pt={1} mb={4}>
                  <Box mb={3} mt={2}>
                    <TextField
                      sx={intputStyle}
                      id="username"
                      type="text"
                      size="small"
                      name="username"
                      variant="outlined"
                      placeholder="Username"
                      fullWidth
                      error={
                        loginForm?.errors?.username &&
                        loginForm?.touched?.username
                      }
                      value={loginForm?.values?.username}
                      helperText={loginForm?.errors?.username}
                      onChange={loginForm?.handleChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box mb={2}>
                    <TextField
                      sx={intputStyle}
                      variant="outlined"
                      id="outlined-password"
                      fullWidth
                      size="small"
                      type={values?.hidden ? 'password' : 'text'}
                      name="password"
                      value={loginForm?.values?.password}
                      onChange={loginForm?.handleChange}
                      error={Boolean(
                        loginForm?.touched?.password &&
                          loginForm?.errors?.password
                      )}
                      helperText={loginForm?.errors?.password}
                      placeholder="Password"
                      autoComplete="off"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              className="icon"
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {values.hidden ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    className="extras"
                  >
                    <Grid
                      item
                      style={{ display: 'flex', alignItems: 'center' }}
                      onClick={handlechecked}
                    >
                      <input
                        type="checkbox"
                        checked={checked}
                        onChange={handlechecked}
                        name="remember"
                        id="remember"
                        style={{ marginLeft: '8px', marginRight: '8px' }}
                      />
                      <Typography
                        display="inline"
                        htmlFor="remember"
                        // @ts-ignore
                        sx={{
                          color: (theme) => theme.palette.common.sapphire,
                          cursor: 'pointer',
                        }}
                        fontSize={12}
                      >
                        Remember Me
                      </Typography>
                    </Grid>
                    <Grid item className="align-items-center">
                      <Typography
                        sx={{
                          color: (theme) => theme.palette.common.sapphire,
                          '&:hover': {
                            color: (theme) => theme.palette.common.echoBlue,
                          },
                          cursor: 'pointer',
                        }}
                        type="submit"
                        fontSize={12}
                        onClick={() => handleOpenForm()}
                      >
                        Forgot password?
                      </Typography>
                    </Grid>
                  </Grid>

                  <Box
                    display="flex"
                    alignItems="center"
                    flexGrow={1}
                    flexDirection="column"
                    mt={4}
                  >
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      style={{ width: '318px' }}
                      onClick={() => loginForm.handleSubmit()}
                    >
                      Login
                    </Button>
                  </Box>
                </Box>
                <Box display="flex" justifyContent="flex-end" pr={4} pb={4}>
                  <img
                    height="32px"
                    width="80px"
                    src={PoweredBy}
                    alt="PoweredBy Solytics Partners"
                  />
                </Box>
              </Card>
            </Grid>
          </Box>
        </Box>
      )}
    </Box>
  );
}
export default Login;
