import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Typography } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { ReactComponent as ExpandLess } from '../../assets/images/ExpandLess.svg';
import { ReactComponent as ExpandMore } from '../../assets/images/ModuleDropDown.svg';
import { SIDEBAR_MENUS } from '../../const/SidebarMenu';

const style = {
  '&:hover': {
    color: (theme) => theme.palette.common.sapphire,
    bgcolor: (theme) => theme.palette.common.lightBlue2,
    borderRadius: 2,
  },
  '&.Mui-selected': {
    color: (theme) => theme.palette.common.sapphire,
    backgroundColor: (theme) => theme.palette.common.lightBlue2,
  },
  '&.Mui-selected:hover': {
    color: (theme) => theme.palette.common.sapphire,
    backgroundColor: (theme) => theme.palette.common.lightBlue2,
  },
  display: 'flex',
  gap: 2,
  color: (theme) => theme.palette.common.sapphire,
  borderRadius: 2,
  padding: (theme) => theme.spacing(2),
  marginTop: (theme) => theme.spacing(2),
  height: 60,
};
/**
 * @function SingleLevel
 * @description - SingleLevel - Building UI of SingleLevel for displaying list of
 * submodules as sidebar of a particular module
 * @param {object} props - props object
 * @property {object} item - data of submodule
 * @property {function} navigate - function to navigate from one url to another
 * @property {function} setOpen - function to open the UI of a particular submodule
 * @returns {React.ReactElement} SingleLevel  UI
 * @example <SingleLevel item={item} navigate={navigate}/>
 */

const SingleLevel = ({ item, navigate, setModuleName }) => (
  <ListItemButton
    sx={{ ...style, paddingLeft: 0 }}
    onClick={() => {
      navigate(item?.route);
      setModuleName(item.name);
      localStorage.setItem('navSelectedIndex', item.route);
    }}
    selected={localStorage.getItem('navSelectedIndex') === item.route}
  >
    <Avatar
      variant="square"
      src={item.iconActive}
      sx={{ width: 22, height: 22 }}
    />
    <ListItemText
      primary={
        <Typography variant="subtitle2" fontWeight="450">
          {item.name}
        </Typography>
      }
    />
  </ListItemButton>
);
/**
 * @function SidebarMenu
 * @description - SidebarMenu - Building UI of SidebarMenu for displaying list of
 * modules as sidebar menu
 * @returns {React.ReactElement} SidebarMenu  UI
 * @example <SidebarMenu item={item} navigate={navigate}/>
 */
function SidebarMenu({ sidebarOpen }) {
  const navigate = useNavigate();
  const [moduleName, setModuleName] = useState('');
  const [open, setOpen] = useState(false);
  /**
   *      @function handleClick
   * @description function for displaying the UI of a particular module
   * when we click on that particular module
   * @param {object} item - data of a particular module
   * @returns {void}
   * @example handleClick(item)
   */
  const handleClick = (item) => {
    setOpen(!open);
    setModuleName(item.name);
    if (!item.items) {
      navigate(item?.route);
      localStorage.setItem('navSelectedIndex', item.route);
    }
  };
  return (
    <>
      {SIDEBAR_MENUS.map((item) => (
        <React.Fragment key={item.name}>
          <ListItemButton
            sx={style}
            onClick={() => handleClick(item)}
            selected={
              localStorage.getItem('navSelectedIndex') === item?.route ||
              localStorage.getItem('navSelectedIndex')?.startsWith(item?.route)
            }
          >
            <Avatar
              variant="square"
              src={item.iconActive}
              sx={{ width: 22, height: 22 }}
            />
            <ListItemText
              primary={
                <Typography variant="subtitle2" fontWeight="450">
                  {item.name}
                </Typography>
              }
            />
            {item.items ? (
              item.name === moduleName && open ? (
                <ExpandLess width={18} height={18} />
              ) : (
                <ExpandMore width={18} height={18} />
              )
            ) : null}
          </ListItemButton>
          {item.items && (
            <Collapse
              in={item.name === moduleName && open && sidebarOpen}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding sx={{ pl: 4 }}>
                {item.items.map((child) => (
                  <SingleLevel
                    key={child.name}
                    item={child}
                    navigate={navigate}
                    setModuleName={setModuleName}
                  />
                ))}
              </List>
            </Collapse>
          )}
        </React.Fragment>
      ))}
    </>
  );
}

SingleLevel.propTypes = {
  item: PropTypes.oneOfType([PropTypes.object]).isRequired,
  navigate: PropTypes.func.isRequired,
  setModuleName: PropTypes.func.isRequired,
};
SidebarMenu.propTypes = {
  sidebarOpen: PropTypes.bool.isRequired,
};

export default SidebarMenu;
