// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Drawer } from '@mui/material';
import SidebarMenu from './SidebarMenu';

/**
 * @function Sidebar
 * @description - A sidebar component that can open or close based on user interaction.
 * This component uses a persistent drawer that opens on mouse over and can be controlled through external state. It is commonly used for navigation or menu options.
 * @param {object} props - The properties object.
 * @property {boolean} props.open - A flag indicating whether the sidebar is open.
 * @property {function} props.setOpen - Function to set the open state of the sidebar.
 * @property {function} props.handleDrawerToggle - Function to toggle the drawer's open/close state.
 * @property {number} props.drawerWidth - The width of the drawer in pixels.
 * @returns {React.ReactElement} The Sidebar component.
 * @example
 * <Sidebar
 *   open={isSidebarOpen}
 *   setOpen={setSidebarOpen}
 *   handleDrawerToggle={toggleSidebar}
 * />
 */
const Sidebar = ({ open, setOpen, handleDrawerToggle, drawerWidth }) => (
  <Box sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} id="noprint">
    <Drawer
      variant="persistent"
      open={true}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      onMouseOver={() => {
        setOpen(true);
      }}
      sx={{
        flexShrink: 0,
        overflowY: 'scroll',
        overflowX: 'hidden',
        scrollbarGutter: 'stable',
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: (theme) => theme.palette.common.lightBlue1,
          transition: '0.2s',
          '&::-webkit-scrollbar': {
            width: '6px',
            height: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: (theme) => theme.palette.common.echoBlue,
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: (theme) => theme.palette.common.echoBlue,
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: (theme) => theme.palette.common.lightBlue1,
          },
        },
      }}
    >
      <Box ml={3} mr={1} mt={17} display="flex" flexDirection="column">
        <SidebarMenu sidebarOpen={open} />
      </Box>
    </Drawer>
  </Box>
);
Sidebar.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  drawerWidth: PropTypes.number.isRequired,
};
export default Sidebar;
