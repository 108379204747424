// @ts-nocheck
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { selectAuth, setAuth } from '../../state/slices/appSlice';
import { API_ENDPOINTS } from '../../const/ApiEndpoints';
import keycloakConfig from '../../keyclock-config';
/**
 * @function useAuth
 * @description hook for checking the authentication of a particular user
 * @returns {object} -returning multiple methods and states for authentication of user
 * @example const {isLoggedIn,login,logout,resetPassword}=useAuth()
 */
const useAuth = () => {
  const { isLoggedIn } = useSelector(selectAuth);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  /**
   * @function login
   * @description API call for login of a user
   * @param {object} data - payload data
   * @returns {Promise} AxiosPromise
   * @example  login(data)
   */
  const login = (data) =>
    axios.post(API_ENDPOINTS.BASE_URL + API_ENDPOINTS.AUTH.LOGIN, data).then(
      (res) => {
        dispatch(
          setAuth({
            isLoggedIn: true,
            token: res.data.tokens.access_token,
            refresh: res.data.tokens.refresh_token,
            user: res.data.user,
          })
        );
        enqueueSnackbar('Logged in successfully', {
          variant: 'success',
        });
      },
      (err) => {
        throw err;
      }
    );

  /**
   * @function loginDetails
   * @description API call for loginDetails of a user
   * @returns {Promise} AxiosPromise
   * @example  loginDetails()
   */
  const loginDetails = () =>
    axios.get(API_ENDPOINTS.BASE_URL + API_ENDPOINTS.AUTH.LOGIN).then(
      (res) => {
        dispatch(
          setAuth({
            isLoggedIn: true,
            token: res.data.tokens.access_token,
            refresh: keycloakConfig.refreshToken,
            user: res.data.user,
          })
        );
        if (window.apm) {
          // www.elastic.co/guide/en/apm/agent/rum-js/current/agent-api.html#apm-set-user-context
          window.apm.setUserContext({
            username: res?.data?.user?.username,
            id: res?.data?.user?.id,
            email: res?.data?.user?.email,
          });
        }
        enqueueSnackbar('Logged in successfully', {
          variant: 'success',
        });
      },
      (err) => {
        throw err;
      }
    );
  /**
   * @function resetPassword
   * @description API call for resetting the password of a user
   * @param {object} data - payload data
   * @returns {Promise} AxiosPromise
   * @example  resetPassword(data)
   */
  const resetPassword = (data) =>
    axios
      .post(API_ENDPOINTS.BASE_URL + API_ENDPOINTS.AUTH.RESET_PASSWORD, data)
      .then(
        (res) => res,
        (err) => err
      );
  /**
   * @function changeNewPassword
   * @description API call for changing the password of a user
   * @param {object} data - payload data
   * @returns {Promise} AxiosPromise
   * @example  changeNewPassword(data)
   */
  const changeNewPassword = (data) =>
    axios
      .patch(API_ENDPOINTS.BASE_URL + API_ENDPOINTS.AUTH.CHANGE_PASSWORD, data)
      .then(
        (res) => res,
        (err) => err
      );
  /**
   * @function logout
   * @description API is called when user is log out
   * @returns {void}
   * @example  logout()
   */
  const logout = () => {
    dispatch(
      setAuth({
        isLoggedIn: false,
        token: null,
        user: null,
        refresh: null,
      })
    );
    keycloakConfig.logout();
    localStorage.removeItem('ACTIVE_SCENARIO');
    enqueueSnackbar('Logout is successful', {
      variant: 'error',
    });
  };

  return {
    isLoggedIn,
    login,
    logout,
    resetPassword,
    changeNewPassword,
    loginDetails,
  };
};
export default useAuth;
