import React, { lazy, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Navigate, Route, Routes } from 'react-router-dom';
import Layout from '../pages/Layout/Layout';
import PageNotFound from '../components/Common/PageNotFound';
import keycloakConfig from '../keyclock-config';

const ScenarioManager = lazy(
  () => import('../pages/ScenarioManager/TMSScenarioManager')
);

const CreateScenario = lazy(
  () => import('../pages/ScenarioManager/CreateScenario/CreateScenarioPage')
);

const EditScenario = lazy(
  () => import('../pages/ScenarioManager/CreateScenario/EditScenarioPage')
);

// const DataIngestion = lazy(
//   () => import('../pages/DataManager/DataIngestion/DataManager')
// );

const DataIngestion = lazy(
  () => import('../pages/DataManager/GlencoreDataManager/GlencoreDataManager')
);

const DataOverview = lazy(
  () => import('../pages/DataManager/DataOverview/DataOverview')
);
const ScenarioOverview = lazy(
  () => import('../pages/DataManager/DataIngestion/DataIngestion')
);
const ExploratoryAnalysis = lazy(
  () => import('../pages/ExploratoryAnalysis/ExploratoryAnalysis')
);
const Outlier = lazy(() => import('../pages/OutlierAnalysis/Outlier'));
const TuningData = lazy(
  () => import('../pages/TuningAnalysis/TuningData/TuningData')
);
const ConsolidatedView = lazy(
  () => import('../pages/TuningAnalysis/ConsolidatedView/ConsolidatedView')
);
const AtlBtlAnalysis = lazy(
  () => import('../pages/Sampling/ABAnalysis/AtlBtlAnalysis')
);
const SampleGeneration = lazy(
  () => import('../pages/Sampling/SampleGeneration/SampleGeneration')
);
const ImpactAnalysis = lazy(
  () => import('../pages/ImpactAnalysis/ImpactAnalysis')
);
const WhatIf = lazy(() => import('../pages/ImpactAnalysis/WhatIf'));
const Documentation = lazy(
  () => import('../pages/Documentation/Documentation')
);
// const Reconciliation = lazy(() => import('../pages/Reconciliation'));
const AddSourcePage = lazy(
  () => import('../pages/DataManager/DataIngestion/Add Source/AddSourcePage')
);

const AddDataPage = lazy(
  () => import('../pages/DataManager/DataIngestion/Add Data/AddDataPage')
);

const EditSourcePage = lazy(
  () => import('../pages/DataManager/DataIngestion/Add Source/EditSourcePage')
);

const ImportThresholdFilesPage = lazy(
  () => import('../pages/DataManager/DataOverview/ImportThresholdFiles')
);

const Auditability = lazy(
  () => import('../pages/History/Auditability/Auditability')
);
const ScenerioHistory = lazy(
  () => import('../pages/History/ScenerioHistory/ScenerioHistory')
);

const Transaction = lazy(
  () => import('../pages/History/TransactionHistory/TransactionHistory')
);

const ProtectedRoutes = ({ isLoggedIn }) => {
  useEffect(() => {
    if (!isLoggedIn) {
      keycloakConfig.logout();
    }
  }, [isLoggedIn]);

  return isLoggedIn ? (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route
          path="/"
          element={<Navigate to="/datamanager/dataingestion" />}
        />
        <Route path="/scenariomanager" element={<ScenarioManager />} />
        <Route path="datamanager/dataingestion" element={<DataIngestion />} />
        <Route
          path="datamanager/dataingestion/add-source"
          element={<AddSourcePage />}
        />

        <Route
          path="/datamanager/dataingestion/edit-source/:_userSchemaId"
          element={<EditSourcePage />}
        />
        <Route
          path="/datamanager/dataingestion/add-data"
          element={<AddDataPage />}
        />
        <Route
          path="/tuningManager/dataOverview"
          element={<ScenarioOverview />}
        />
        <Route
          path="/tuningManager/tuningOverview"
          element={<DataOverview />}
        />
        <Route
          path="/tuningManager/dataOverview/importthresholdfiles"
          element={<ImportThresholdFilesPage />}
        />
        <Route path="exploratoryAnalysis" element={<ExploratoryAnalysis />} />
        <Route path="outlierAnalysis" element={<Outlier />} />
        <Route path="tuningAnalysis/tuningData" element={<TuningData />} />
        <Route
          path="tuningAnalysis/consolidatedView"
          element={<ConsolidatedView />}
        />
        <Route path="sampling/atlbtlAnalysis" element={<AtlBtlAnalysis />} />
        <Route
          path="sampling/sampleGeneration"
          element={<SampleGeneration />}
        />
        <Route path="impactAnalysis" element={<ImpactAnalysis />} />
        <Route path="WhatIf" element={<WhatIf />} />
        <Route path="/documentation" element={<Documentation />} />
        <Route
          path="/scenariomanager/createscenario/:_scenarioID"
          element={<CreateScenario />}
        />
        <Route
          path="/scenariomanager/editscenario/:_scenarioID"
          element={<EditScenario />}
        />
        {/* <Route path="reconciliation" element={<Reconciliation />} /> */}
        <Route path="/history/auditability" element={<Auditability />} />
        <Route path="/history/scenarioHistory" element={<ScenerioHistory />} />
        <Route path="/history/ScenerioHistory" element={<ScenerioHistory />} />
        <Route path="/history/thresholdHistory" element={<Transaction />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  ) : null;
};

ProtectedRoutes.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
};
export default ProtectedRoutes;
